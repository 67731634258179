/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-syntax */

import { RestSerializer, Serializer } from 'miragejs';
import {
  snakeCase,
} from 'lodash';

const ApplicationSerializer = RestSerializer.extend({
  root: false,
  embed: true,
  typeKeyForModel(model) {
    return snakeCase(model.modelName);
  },
  keyForAttribute(key) {
    return snakeCase(key);
  },
  keyForRelationship(key) {
    return snakeCase(key);
  },
  keyForEmbeddedRelationship(key) {
    return snakeCase(key);
  },
});

const ChecklistSerializer = ApplicationSerializer.extend({
  keyForEmbeddedRelationship(key) {
    return key === 'checklist_items' ? 'items' : snakeCase(key);
  },
});

export default {
  user: ApplicationSerializer.extend({}),
  project: ApplicationSerializer.extend({
    include(request) {
      const include = [];
      if (request.queryParams.includeRooms) include.push('rooms');
      return include;
    },
    serialize(object, request) {
      const json = Serializer.prototype.serialize.apply(this, arguments);
      if (request.queryParams.markUserType) {
        const userId = parseInt((
          request.requestHeaders.Authorization
          || request.requestHeaders.authorization
        ).split('-')[1], 10);

        json.forEach((project) => {
          if (project.owner.id === userId) {
            project.user_type = 'owner';
          } else if (project.admins.filter((admin) => admin.id === userId).length > 0) {
            project.user_type = 'admin';
          } else {
            project.user_type = 'basic';
          }
        });
      }
      if (request.queryParams.addNumOfItems) {
        json.rooms.forEach((room) => {
          room.num_of_items = room.items.length;
          delete room.items;
        });
      }
      return json;
    },
  }),
  room: ApplicationSerializer.extend({
    include(request) {
      const include = [];
      if (request.queryParams.includeItems) include.push('items');
      return include;
    },
    serialize(object, request) {
      const json = Serializer.prototype.serialize.apply(this, arguments);
      if (request.queryParams.includeItems) {
        json.items.forEach((item) => {
          delete item.images;
        });
      }
      return json;
    },
  }),
  item: ApplicationSerializer,
  checklist: ChecklistSerializer.extend({
    include: ['checklist_items'],
  }),
  checklistItem: ApplicationSerializer,
  event: ApplicationSerializer.extend({
    serialize(object, request) {
      let json = Serializer.prototype.serialize.apply(this, arguments);
      if (request.queryParams.userEvents) {
        json = { items: json };
      }
      return json;
    },
  }),
  reward: ApplicationSerializer,
};
