import {
  CHECKLIST_GET,
  CHECKLISTS_GET,
  CHECKLIST_ITEM_COMPLETE,
  CHECKLIST_ITEM_INCOMPLETE,
  CHECKLIST_ITEM_ON_WISHLIST,
  CHECKLIST_ITEM_NOT_ON_WISHLIST,
} from '@/store/actions';
import {
  USER_TOKEN,
} from '@/store/getters';
import {
  checklistGet,
  checklistsGet,
  checklistItemComplete,
  checklistItemIncomplete,
  checklistItemOnWishlist,
  checklistItemNotOnWishlist,
} from '@/utils/api/userChecklist';

const actions = {
  async [CHECKLISTS_GET](context, projectId) {
    const token = context.getters[USER_TOKEN];
    return checklistsGet(token, projectId);
  },
  async [CHECKLIST_GET](context, ids) {
    const token = context.getters[USER_TOKEN];
    return checklistGet(token, ids);
  },
  async [CHECKLIST_ITEM_COMPLETE](context, ids) {
    const token = context.getters[USER_TOKEN];
    return checklistItemComplete(token, ids);
  },
  async [CHECKLIST_ITEM_INCOMPLETE](context, ids) {
    const token = context.getters[USER_TOKEN];
    return checklistItemIncomplete(token, ids);
  },
  async [CHECKLIST_ITEM_ON_WISHLIST](context, ids) {
    const token = context.getters[USER_TOKEN];
    return checklistItemOnWishlist(token, ids);
  },
  async [CHECKLIST_ITEM_NOT_ON_WISHLIST](context, ids) {
    const token = context.getters[USER_TOKEN];
    return checklistItemNotOnWishlist(token, ids);
  },
};
const mutations = {
};
const getters = {
};
const state = () => ({
});

export default {
  state,
  mutations,
  actions,
  getters,
};
