import {
  ADMIN_CHECKLIST_POST,
  ADMIN_CHECKLIST_GET,
  ADMIN_CHECKLIST_PUT,
  ADMIN_CHECKLIST_DELETE,
  ADMIN_CHECKLISTS_GET,
  ADMIN_CHECKLIST_ITEM_POST,
  ADMIN_CHECKLIST_ITEM_PUT,
  ADMIN_CHECKLIST_ITEM_DELETE,
} from '@/store/actions';
import {
  USER_TOKEN,
} from '@/store/getters';
import {
  adminChecklistCreate,
  adminChecklistGet,
  adminChecklistPut,
  adminChecklistDelete,
  adminChecklistsGet,
  adminChecklistItemCreate,
  adminChecklistItemPut,
  adminChecklistItemDelete,
} from '@/utils/api/adminChecklist';

const actions = {
  async [ADMIN_CHECKLIST_POST](context, data) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistCreate(token, data);
  },
  async [ADMIN_CHECKLIST_GET](context, checklistId) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistGet(token, checklistId);
  },
  async [ADMIN_CHECKLIST_PUT](context, data) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistPut(token, data);
  },
  async [ADMIN_CHECKLIST_DELETE](context, checklistId) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistDelete(token, checklistId);
  },
  async [ADMIN_CHECKLISTS_GET](context) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistsGet(token);
  },
  async [ADMIN_CHECKLIST_ITEM_POST](context, data) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistItemCreate(token, data);
  },
  async [ADMIN_CHECKLIST_ITEM_PUT](context, data) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistItemPut(token, data);
  },
  async [ADMIN_CHECKLIST_ITEM_DELETE](context, checklistId) {
    const token = context.getters[USER_TOKEN];
    return adminChecklistItemDelete(token, checklistId);
  },

};
const mutations = {
};
const getters = {
};
const state = () => ({
});

export default {
  state,
  mutations,
  actions,
  getters,
};
