/* eslint-disable no-undef */
/* eslint-disable brace-style */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta';

import { createServer, Response } from 'miragejs';
import { makeServer } from '@/server/server';
import interceptors from '@/utils/api/interceptors';
import './registerServiceWorker';
import router from '@/router';
import store from '@/store';
import App from './App.vue';
import '@/assets/css/tailwind.css';
import '@/assets/css/colors.css';

interceptors(axios);

// runs mirage if with Cypress ONLY if the mode is mock
if (window.Cypress && process.env.NODE_ENV === 'mock') {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  const otherDomains = [];
  const methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      for (const domain of ['/', ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {
            const [status, headers, body] = await window.handleFromCypress(
              request,
            );
            return new Response(status, headers, body);
          });
        }
      }
      // If your central server has any calls to passthrough(),
      // you'll need to duplicate them here
      // this.passthrough('https://analytics.google.com')
    },
  });
}
// runs mirage if mode is mock
else if (process.env.NODE_ENV === 'mock') {
  makeServer();
}

Vue.use(VueAxios, axios);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
  window.app = app;
}
