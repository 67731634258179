/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

/**
 * Creates an event with the given parameters
 * @param {String} token Validation token
 * @param {Object} data { title, description, start, end }
 */
export async function eventCreate(token, {
  title, description, start, end,
}) {
  return axios.post(
    '/api/calendar/event',
    {
      title,
      description,
      start_datetime: start,
      end_datetime: end,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets all the events that the user is scheduled for
 * @param {String} token Validation token
 */
export async function eventsGet(token) {
  return axios.get(
    '/api/calendar/events',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets all global event start and end times
 * @param {String} token Validation token
 */
export async function eventsGlobalGet(token) {
  return axios.get(
    '/api/calendar/global_events',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the item corresponding to the given projectId, roomId, itemId
 * @param {String} token Validation token
 * @param {Object} id event Id
 */
export async function eventDelete(token, id) {
  return axios.delete(
    `/api/calendar/event/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
