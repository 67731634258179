/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { fileToBase64Packer } from '../images/fileToBase64packer';

/**
 * Creates a room corresponding to the given projectId
 * @param {String} token Validation token
 * @param {Object} data { projectId, room }
 */
export async function roomCreate(token, data) {
  return axios.post(
    `/api/project/${data.projectId}/room`,
    {
      name: data.room.name,
      floor: data.room.floor,
      picture: await fileToBase64Packer(data.room.picture),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets the room corresponding to the given projectId and roomId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId }
 */
export async function roomGet(token, data) {
  return axios.get(
    `/api/project/${data.projectId}/room/${data.roomId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Updates the room corresponding to the given projectId, and roomId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, room }
 */
export async function roomPut(token, data) {
  const body = {
    name: data.room.name,
    floor: data.room.floor,
  };
  if (data.room.picture) body.picture = await fileToBase64Packer(data.room.picture);
  return axios.put(
    `/api/project/${data.projectId}/room/${data.roomId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the room corresponding to the given projectId and roomId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId }
 */
export async function roomDelete(token, data) {
  return axios.delete(
    `/api/project/${data.projectId}/room/${data.roomId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
