<template>
  <div
    id="app"
    class="bg-background text-content"
    :class="{'dark': darkMode}"
  >
    <router-view/>
  </div>
</template>

<script>
import { USER_GET } from '@/store/actions';
import { USER_STORE_TOKEN } from '@/store/mutations';
import { setSavedDarkMode } from '@/utils/darkMode';

export default {
  computed: {
    darkMode() {
      console.log(this.$store.getters.GET_DARK_MODE);
      return this.$store.getters.GET_DARK_MODE;
    },
  },
  beforeMount() {
    const token = localStorage.getItem(`${process.env.NODE_ENV}-token`);
    if (token) {
      this.$store.commit(USER_STORE_TOKEN, token);
      this.$store.dispatch(USER_GET, token);
    }
    this.$store.commit('SET_DARK_MODE', setSavedDarkMode());
  },
};
</script>
<style>
html, body, #app {
  height: 100%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
