/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { fileToBase64Packer } from '../images/fileToBase64packer';

export async function userCreate(user) {
  return axios.post(
    '/api/user',
    {
      ...user,
    },
  );
}

export async function userEdit(token, user) {
  const body = {
    ...user,
  };
  if (body.picture) body.picture = await fileToBase64Packer(body.picture);
  return axios.put(
    '/api/user',
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).catch((error) => {
    throw error;
  });
}

export async function userSignIn(user) {
  const base64 = window.btoa(`${user.email}:${user.password}`);
  return axios.get(
    '/api/user',
    {
      headers: {
        Authorization: `Basic ${base64}`,
      },
    },
  ).then((response) => response.data);
}

export async function userData(token) {
  return axios.get(
    '/api/user',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function userValidateEmail(token) {
  return axios.post(
    '/api/user/validate',
    {
      validate_user_token: token,
    },
  ).then((response) => response.data);
}

export async function userResetPassword(resetPasswordAndToken) {
  return axios.post(
    '/api/user/reset_password',
    {
      new_password: resetPasswordAndToken.password,
      reset_password_token: resetPasswordAndToken.token,
    },
  );
}

export async function userForgotPassword(email) {
  return axios.post(
    '/api/user/forgot_password',
    {
      email,
    },
  ).then((response) => response.data);
}

export async function userWalkthroughUpdate(token, stage) {
  return axios.post(
    '/api/user/completed_walkthrough',
    { stage },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  ).then((response) => response.data);
}
export async function userInvite(token, email) {
  return axios.post(
    '/api/user/invite',
    { email },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  ).then((response) => response.data);
}

/**
 * Marks the given notification as read
 * @param {String} token Validation token
 * @param {Object} notificationId notification to be set as read
 */
export async function userNotificationRead(token, notificationId) {
  return axios.post(
    `/api/user/notification/${notificationId}/read`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function userTriggerPayment(token, { priceId, isPromo }) {
  let stripe = null;
  if (!window.Cypress) {
    // eslint-disable-next-line no-undef
    stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
  }

  return axios.post(
    '/api/user/create_checkout_session',
    { priceId },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => {
    if (!isPromo) {
      stripe.redirectToCheckout({ ...response.data });
    }
  });
}

export async function userCompletePayment(token, code) {
  return axios.post(
    '/api/user/verify_purchase',
    {
      session_id: code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function userPointsAdd(token, name) {
  return axios.post(
    '/api/user/rewards',
    { name },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function userRedeemReward(token, { pointsRequired, percentOff }) {
  return axios.post(
    '/api/user/rewards/redeem',
    {
      points_required: pointsRequired,
      percent_off: percentOff,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function userAvailableRewards(token) {
  return axios.get(
    '/api/user/rewards',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function userDeleteAccount(token) {
  return axios.delete(
    '/api/user/delete',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
