import {
  ROOM_GET,
  ITEM_POST, ITEM_GET, ITEM_PUT, ITEM_SHARE, ITEM_DELETE,
} from '@/store/actions';
import { ITEM_SET } from '@/store/mutations';
import {
  USER_TOKEN,
} from '@/store/getters';
import {
  updateItemFields, getItemFields,
} from '@/store/mappers';
import {
  itemCreate, itemGet, itemPut, itemDelete, itemShare,
} from '@/utils/api/item';

const actions = {
  async [ITEM_POST](context, item) {
    const token = context.getters[USER_TOKEN];
    return itemCreate(token, item);
  },
  async [ITEM_GET](context, ids) {
    const token = context.getters[USER_TOKEN];
    const retrievedItem = itemGet(token, ids)
      .then((item) => context.commit(ITEM_SET, item));
    context.dispatch(ROOM_GET, ids)
      .catch((error) => { throw error; });
    return retrievedItem;
  },
  async [ITEM_PUT](context, item) {
    const token = context.getters[USER_TOKEN];
    return itemPut(token, item);
  },
  async [ITEM_SHARE](context, item) {
    const token = context.getters[USER_TOKEN];
    return itemShare(token, item);
  },
  async [ITEM_DELETE](context, itemId) {
    const token = context.getters[USER_TOKEN];
    return itemDelete(token, itemId);
  },
};
const mutations = {
  [ITEM_SET]: (state, item) => {
    state.item = item;
  },
  updateItemFields,
};
const getters = {
  getItemFields,
};
const state = () => ({
  item: {},
  items: {},
});

export default {
  state,
  mutations,
  actions,
  getters,
};
