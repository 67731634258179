/* eslint-disable import/prefer-default-export */
export function setDarkMode(boolVal) {
  localStorage.setItem('darkMode', boolVal);

  const primary = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--primary-dark' : '--primary-light');
  document.documentElement.style.setProperty('--primary', primary);

  const secondary = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--secondary-dark' : '--secondary-light');
  document.documentElement.style.setProperty('--secondary', secondary);

  const content = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--content-dark' : '--content-light');
  document.documentElement.style.setProperty('--content', content);

  const background = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--background-dark' : '--background-light');
  document.documentElement.style.setProperty('--background', background);

  const backfill = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--backfill-dark' : '--backfill-light');
  document.documentElement.style.setProperty('--backfill', backfill);

  const input = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--input-dark' : '--input-light');
  document.documentElement.style.setProperty('--input', input);

  const contrast = getComputedStyle(document.documentElement)
    .getPropertyValue(boolVal ? '--contrast-dark' : '--contrast-light');
  document.documentElement.style.setProperty('--contrast', contrast);
}

export function getDarkMode() {
  const darkModeLS = localStorage.getItem('darkMode');
  if (darkModeLS) return darkModeLS === 'true';

  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDarkMode;
}

export function setSavedDarkMode() {
  if (localStorage.getItem('darkMode')) {
    setDarkMode(getDarkMode());
  }
  return getDarkMode();
}
