/* eslint-disable no-param-reassign */
import {
  USER_ADD_POINTS,
  PROJECT_POST, PROJECT_GET, PROJECTS_ALL, PROJECT_PUT, PROJECT_DELETE,
  PROJECT_ADD_USER, PROJECT_ACCEPT_INVITE, PROJECT_REMOVE_USER,
  PROJECT_UPDATE_USER_PERMISSIONS, PROJECT_ITEMS_GET,
  PROJECT_SHARE_ITEMS,
} from '@/store/actions';
import {
  PROJECT_ACCEPT_INVITE_TOKEN_SETTER, PROJECT_SET, PROJECTS_SET,
  PROJECT_ITEMS_SET,
} from '@/store/mutations';
import {
  USER_TOKEN,
  PROJECT_PERMISSIONS, PROJECT_PERMISSIONS_CURRENT, PROJECT_INVITE_TOKEN,
} from '@/store/getters';
import {
  updateProjectFields, getProjectFields,
} from '@/store/mappers';
import {
  projectCreate, projectGet, projectItemsGet, projectsAll, projectPut, projectDelete,
  projectAddUser, projectAcceptInvite, projectUpdateUserPermissions, projectRemoveUser,
  projectShareItems,
} from '@/utils/api/project';

const actions = {
  async [PROJECT_POST](context, project) {
    const token = context.getters[USER_TOKEN];
    return projectCreate(token, project)
      .then((response) => {
        context.dispatch(USER_ADD_POINTS, 'Create Project');
        return response;
      });
  },
  async [PROJECT_GET](context, projectId) {
    const token = context.getters[USER_TOKEN];
    return projectGet(token, projectId)
      .then((project) => {
        context.commit(PROJECT_SET, project);
        return project;
      });
  },
  async [PROJECT_ITEMS_GET](context, projectId) {
    const token = context.getters[USER_TOKEN];
    return projectItemsGet(token, projectId)
      .then((project) => context.commit(PROJECT_ITEMS_SET, project));
  },
  async [PROJECTS_ALL](context) {
    const token = context.getters[USER_TOKEN];
    return projectsAll(token)
      .then((projectsList) => {
        context.commit(PROJECTS_SET, projectsList);
        return projectsList;
      });
  },
  async [PROJECT_PUT](context, projectData) {
    if (projectData.project.timeline) {
      projectData.project.timeline = JSON.stringify(projectData.project.timeline);
    }
    const token = context.getters[USER_TOKEN];
    return projectPut(token, projectData)
      .then((project) => {
        context.commit(PROJECT_SET, project);
      });
  },
  async [PROJECT_DELETE](context, projectId) {
    const token = context.getters[USER_TOKEN];
    return projectDelete(token, projectId);
  },
  async [PROJECT_ADD_USER](context, data) {
    const token = context.getters[USER_TOKEN];
    return projectAddUser(token, data);
  },
  async [PROJECT_ACCEPT_INVITE](context) {
    const projectInviteToken = context.getters[PROJECT_INVITE_TOKEN];
    if (projectInviteToken) {
      const token = context.getters[USER_TOKEN];
      return projectAcceptInvite(token, projectInviteToken);
    }
    return null;
  },
  async [PROJECT_UPDATE_USER_PERMISSIONS](context, data) {
    const token = context.getters[USER_TOKEN];
    return projectUpdateUserPermissions(token, data);
  },
  async [PROJECT_REMOVE_USER](context, data) {
    const token = context.getters[USER_TOKEN];
    return projectRemoveUser(token, data);
  },
  async [PROJECT_SHARE_ITEMS](context, data) {
    const token = context.getters[USER_TOKEN];
    return projectShareItems(token, data);
  },
};
const mutations = {
  [PROJECT_ACCEPT_INVITE_TOKEN_SETTER](state, value) { state.projectAddUserToken = value; },
  [PROJECT_SET]: (state, project) => {
    if (project.timeline) project.timeline = JSON.parse(project.timeline);
    state.project = {
      ...state.project,
      ...project,
    };
  },
  [PROJECTS_SET]: (state, projectsList) => { state.projectsList = projectsList; },
  [PROJECT_ITEMS_SET]: (state, projectsItems) => { state.projectItems = projectsItems; },
  updateProjectFields,
};
const getters = {
  [PROJECT_INVITE_TOKEN]: (state) => state.projectAddUserToken,
  [PROJECT_PERMISSIONS]: () => (project) => project.user_type,
  [PROJECT_PERMISSIONS_CURRENT]: (state, _, rootState) => {
    if (!state.project.owner) {
      return '----';
    }
    const userId = rootState.user.user.id;
    if (`${state.project.owner.id}` === `${userId}`) return 'owner';
    if (state.project.admins.filter((admin) => admin.id === userId).length > 0) return 'admin';
    return 'basic';
  },
  getProjectFields,
};
const state = () => ({
  projectAddUserToken: null,
  project: {},
  projects: {},
  projectsList: [],
  projectItems: [],
});

export default {
  state,
  mutations,
  actions,
  getters,
};
