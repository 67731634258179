import {
  USER_REMOVE_DETAILS,
} from '../../store/mutations';
import { USER_TOKEN } from '../../store/getters';
import store from '../../store/index';

export default function (axios) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorCode = error.response ? error.response.status : '';
      const token = store.getters[USER_TOKEN];
      if (errorCode === 401 && token) {
        store.commit(USER_REMOVE_DETAILS);
        window.location = '/sign-in';
      }
      if (errorCode === 500 || errorCode === 501 || errorCode === 502) {
        store.commit('ERROR_UPDATE', {
          message: `An unknonwn error has occured. Please try again later (${errorCode})`,
        });
      }
      throw error;
    },
  );
}
