/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { fileToBase64Packer } from '../images/fileToBase64packer';

/**
 * creates a master checklist
 * @param {String} token Verification token
 * @param {Object} data {name, type, ?picture}
 */
export async function adminChecklistCreate(token, data) {
  const body = {
    name: data.name,
    type: data.type,
  };
  if (data.picture) body.picture = await fileToBase64Packer(data.picture);

  return axios.post(
    '/api/list',
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Returns the specified master checklist
 * @param {String} token Verification token
 * @param {Object} checklistId checklist id
 */
export async function adminChecklistGet(token, checklistId) {
  return axios.get(
    `/api/list/${checklistId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => ({
    name: response.data.name,
    picture: response.data.picture,
    type: response.data.type,
    items: [...response.data.items],
  }));
}

/**
 * Updates the specified master checklist
 * @param {String} token Verification token
 * @param {String} data { checklistId, checklistData }
 */
export async function adminChecklistPut(token, data) {
  const body = {
    name: data.checklistData.name,
    type: data.checklistData.type,
  };
  if (data.checklistData.picture) {
    body.picture = await fileToBase64Packer(data.checklistData.picture);
  }

  return axios.put(
    `/api/list/${data.checklistId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the specified master checklist
 * @param {String} token Verification token
 * @param {String} checklistId Checklist to be deleted
 */
export async function adminChecklistDelete(token, checklistId) {
  return axios.delete(
    `/api/list/${checklistId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Returns the list of master checklists
 * @param {String} token Verification token
 */
export async function adminChecklistsGet(token) {
  return axios.get(
    '/api/lists',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Creates a master checklist item
 * @param {String} token Verification token
 * @param {Object} data {checklistId, itemData}
 */
export async function adminChecklistItemCreate(token, data) {
  return axios.post(
    `/api/list/${data.checklistId}/list_item`,
    {
      name: data.itemData.name,
      url: data.itemData.url,
      description: data.itemData.description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Updates the specified master checklist item
 * @param {String} token Verification token
 * @param {String} data { checklistId, itemId, itemData }
 */
export async function adminChecklistItemPut(token, data) {
  return axios.put(
    `/api/list/${data.checklistId}/list_item/${data.itemId}`,
    {
      ...data.itemData,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the specified master checklist item
 * @param {String} token Verification token
 * @param {String} ids {checklistId, itemId}
 */
export async function adminChecklistItemDelete(token, ids) {
  return axios.delete(
    `/api/list/${ids.checklistId}/list_item/${ids.itemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
