/* eslint-disable brace-style */
import Vue from 'vue';
import VueRouter from 'vue-router';

import { USER_EMAIL_AUTHENTICATE } from '../store/actions';

import {
  PROJECT_ACCEPT_INVITE_TOKEN_SETTER,
} from '../store/mutations';
import store from '../store/index';

const Landing = () => import('../views/Landing.vue');
const Register = () => import('../views/Register.vue');
const SignIn = () => import('../views/SignIn.vue');
const TermsAndConditions = () => import('../views/TermsAndConditions.vue');
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue');
const DataDeletion = () => import('../views/DataDeletion.vue');
const ForgotPassword = () => import('../views/ForgotPassword.vue');
const ResetPassword = () => import('../views/ResetPassword.vue');

const Dashboard = () => import('../views/Dashboard.vue');
const Invite = () => import('../views/Invite.vue');
const ToDo = () => import('../views/ToDo.vue');
const Settings = () => import('../views/Settings.vue');

const PaymentPlan = () => import('../views/PaymentPlan.vue');
const Projects = () => import('../views/projects/Projects.vue');
const ProjectCreate = () => import('../views/projects/ProjectCreate.vue');
const Project = () => import('../views/projects/Project.vue');
const ProjectSettings = () => import('../views/projects/ProjectSettings.vue');
const ProjectTimeline = () => import('../views/projects/timeline/ProjectTimeline.vue');
const ProjectTimelineEditTimeline = () => import('../views/projects/timeline/ProjectTimelineEditTimeline.vue');
const ProjectTimelineEditItem = () => import('../views/projects/timeline/ProjectTimelineEditItem.vue');
const ProjectItemList = () => import('../views/projects/ProjectItemList.vue');
const ProjectWishlist = () => import('../views/projects/checklists/ProjectWishlist.vue');

const RoomCreate = () => import('../views/projects/RoomCreate.vue');
const Room = () => import('../views/projects/Room.vue');
const RoomSettings = () => import('../views/projects/RoomSettings.vue');

const ItemCreate = () => import('../views/projects/ItemCreate.vue');
const Item = () => import('../views/projects/Item.vue');
const ItemSettings = () => import('../views/projects/ItemSettings.vue');

const Checklist = () => import('../views/projects/checklists/Checklist.vue');
const AdminChecklists = () => import('../views/projects/checklists/AdminChecklists.vue');
const AdminChecklist = () => import('../views/projects/checklists/AdminChecklist.vue');
const AdminChecklistCreate = () => import('../views/projects/checklists/AdminChecklistCreate.vue');
const AdminChecklistSettings = () => import('../views/projects/checklists/AdminChecklistSettings.vue');
const Rewards = () => import('../views/Rewards.vue');

const Assistance = () => import('../views/assistance/Assistance.vue');
const AssistanceBooking = () => import('../views/assistance/AssistanceBooking.vue');

const Calculators = () => import('../views/calculators/Calculators.vue');
const CalculatorDownsizing = () => import('../views/calculators/CalculatorDownsizing.vue');
const CalculatorHomeSafetyUpdates = () => import('../views/calculators/CalculatorHomeSafetyUpdates.vue');
const CalculatorMonthlyCosts = () => import('../views/calculators/CalculatorMonthlyCosts.vue');

const PageNotFound = () => import('../views/PageNotFound.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      external: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      external: true,
    },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      external: true,
      canLogInWithToken: true,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      external: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      external: true,
    },
  },
  {
    path: '/data-deletion-instructions-url-for-kept-in-view',
    name: 'DataDeletion',
    component: DataDeletion,
    meta: {},
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      external: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      external: true,
      requiresResetPasswordToken: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      canAcceptsVerificationToken: true,
    },
  },
  {
    path: '/payment-plan',
    name: 'PaymentPlan',
    component: PaymentPlan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/create',
    name: 'ProjectCreate',
    component: ProjectCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/settings',
    name: 'ProjectSettings',
    component: ProjectSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/wishlist',
    name: 'ProjectWishlist',
    component: ProjectWishlist,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/items',
    name: 'ProjectItemList',
    component: ProjectItemList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/timeline',
    name: 'ProjectTimeline',
    component: ProjectTimeline,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/timeline/edit',
    name: 'ProjectTimelineEditTimeline',
    component: ProjectTimelineEditTimeline,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/timeline/:item_id',
    name: 'ProjectTimelineEditItem',
    component: ProjectTimelineEditItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/:tab',
    name: 'Project',
    component: Project,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/create',
    name: 'RoomCreate',
    component: RoomCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/:room_id',
    name: 'Room',
    component: Room,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/:room_id/settings',
    name: 'RoomSettings',
    component: RoomSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/:room_id/item/create',
    name: 'ItemCreate',
    component: ItemCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/:room_id/item/:item_id/settings',
    name: 'ItemSettings',
    component: ItemSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/room/:room_id/item/:item_id',
    name: 'Item',
    component: Item,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:project_id/checklist/:checklist_id',
    name: 'Checklist',
    component: Checklist,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/checklists',
    name: 'AdminChecklists',
    component: AdminChecklists,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/checklist/create',
    name: 'AdminChecklistCreate',
    component: AdminChecklistCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/checklist/:checklist_id/settings',
    name: 'AdminChecklistSettings',
    component: AdminChecklistSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/checklist/:checklist_id',
    name: 'AdminChecklist',
    component: AdminChecklist,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rewards',
    redirect: {
      name: 'Rewards',
      params: {
        tab: 'redeem',
      },
    },
  },
  {
    path: '/rewards/:tab',
    name: 'Rewards',
    component: Rewards,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/assistance/appointment/booking',
    name: 'AssistanceBooking',
    component: AssistanceBooking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/assistance/:tab',
    name: 'Assistance',
    component: Assistance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/to-do',
    name: 'ToDo',
    component: ToDo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calculators',
    name: 'Calculators',
    component: Calculators,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calculator/downsizing',
    name: 'CalculatorDownsizing',
    component: CalculatorDownsizing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calculator/home-safety-updates',
    name: 'CalculatorHomeSafetyUpdates',
    component: CalculatorHomeSafetyUpdates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calculator/monthly-costs',
    name: 'CalculatorMonthlyCosts',
    component: CalculatorMonthlyCosts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/404-page-not-found',
    name: 'PageNotFound',
    component: PageNotFound,
  },
  {
    path: '/404-page-not-found/:page',
    name: 'SpecificPageNotFound',
    component: PageNotFound,
  },
  {
    path: '*',
    redirect: {
      name: 'PageNotFound',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Router guard
router.beforeEach(async (to, from, next) => {
  // Signs user in if token exists
  if (to.meta.canLogInWithToken && to.query.token) {
    localStorage.setItem(`${process.env.NODE_ENV}-token`, to.query.token);
    next({ name: 'Dashboard' });
  }

  // Storing Add Project token if exists
  else if (to.query.add_user_to_project_token) {
    store.commit(PROJECT_ACCEPT_INVITE_TOKEN_SETTER, to.query.add_user_to_project_token);
    const newTo = JSON.parse(JSON.stringify(to));
    delete newTo.query.add_user_to_project_token;
    next(newTo);
  }

  // External guards
  else if (to.meta.external && localStorage.getItem(`${process.env.NODE_ENV}-token`)) {
    next({ name: 'Dashboard' });
  }
  // Entrance verification
  else if (to.meta.canAcceptsVerificationToken && to.query.verification_token) {
    store.dispatch(USER_EMAIL_AUTHENTICATE, to.query.verification_token)
      .then(() => {
        next({
          ...to,
          query: {
            ...to.query,
            verification_token: undefined,
          },
        });
      })
      .catch(() => next('/'));
  }
  // Authenticate users
  else if (to.meta.requiresAuth) {
    const token = localStorage.getItem(`${process.env.NODE_ENV}-token`);
    if (token) { next(); }
    else next('/');
  }
  // Reset password
  else if (to.meta.requiresResetPasswordToken) {
    if (to.query.reset_password_token) next(); else next('/');
  } else next();
});

export default router;
