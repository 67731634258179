import {
  EVENT_POST, EVENTS_GET, EVENTS_GLOBAL_GET, EVENT_DELETE,
} from '@/store/actions';
import {
  USER_TOKEN,
} from '@/store/getters';
import {
  eventCreate, eventsGet, eventsGlobalGet, eventDelete,
} from '@/utils/api/event';

const actions = {
  async [EVENT_POST](context, data) {
    const token = context.getters[USER_TOKEN];
    return eventCreate(token, data);
  },
  async [EVENTS_GET](context) {
    const token = context.getters[USER_TOKEN];
    const data = await eventsGet(token);
    return data.items.filter((event) => new Date(event.end.dateTime) > new Date());
  },
  async [EVENTS_GLOBAL_GET](context) {
    const token = context.getters[USER_TOKEN];
    return eventsGlobalGet(token);
  },
  async [EVENT_DELETE](context, eventId) {
    const token = context.getters[USER_TOKEN];
    return eventDelete(token, eventId);
  },
};
const mutations = {};
const getters = {};
const state = () => ({});

export default {
  state,
  mutations,
  actions,
  getters,
};
