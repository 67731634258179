import Vue from 'vue';
import Vuex from 'vuex';

import user from './handlers/user';
import error from './handlers/error';
import project from './handlers/project';
import room from './handlers/room';
import item from './handlers/item';
import userChecklist from './handlers/userChecklist';
import adminChecklist from './handlers/adminChecklist';
import event from './handlers/event';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    error,
    project,
    room,
    item,
    userChecklist,
    adminChecklist,
    event,
  },
});
