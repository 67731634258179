/* eslint-disable import/prefer-default-export */
export function fileToBase64Packer(file) {
  const extension = file.name.split('.')[file.name.split('.').length - 1];
  const name = file.name.split(`.${extension}`)[0];

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({
      name,
      body: reader.result.split('base64,')[1],
      extension,
      size: file.size,
    });
    reader.onerror = (error) => reject(error);
  });
}
