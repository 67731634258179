import { createHelpers } from 'vuex-map-fields';

export const {
  mapFields: mapProjectFields,
  getProjectFields,
  updateProjectFields,
} = createHelpers({
  getterType: 'getProjectFields',
  mutationType: 'updateProjectFields',
});

export const {
  mapFields: mapRoomFields,
  getRoomFields,
  updateRoomFields,
} = createHelpers({
  getterType: 'getRoomFields',
  mutationType: 'updateRoomFields',
});

export const {
  mapFields: mapItemFields,
  getItemFields,
  updateItemFields,
} = createHelpers({
  getterType: 'getItemFields',
  mutationType: 'updateItemFields',
});
