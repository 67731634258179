import {} from '@/store/actions';
import {} from '@/store/mutations';
import {} from '@/store/getters';
import {} from '@/store/mappers';
import {} from '@/utils/api/room';

const actions = {};
const mutations = {
  ERROR_UPDATE: (state, { message }) => {
    state.message = message;
  },
};
const getters = {
  ERROR_GET_MESSAGE: (state) => state.message,
};
const state = () => ({
  message: '',
});

export default {
  state,
  mutations,
  actions,
  getters,
};
