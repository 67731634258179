export const USER_CREATE = 'USER_CREATE';
export const USER_EDIT = 'USER_EDIT';
export const USER_EMAIL_AUTHENTICATE = 'USER_EMAIL_AUTHENTICATE';
export const USER_TOKEN_VERIFY = 'USER_TOKEN_VERIFY';
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_GET = 'USER_GET';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_WALKTHROUGH_DISPATCH = 'USER_WALKTHROUGH_DISPATCH';
export const USER_INVITE = 'USER_INVITE';
export const USER_NOTIFICATION_READ = 'USER_NOTIFICATION_READ';
export const USER_ADD_POINTS = 'USER_ADD_POINTS';
export const USER_GET_AVAILABLE_REWARDS = 'USER_GET_AVAILABLE_REWARDS';
export const USER_REDEEM_REWARD = 'USER_REDEEM_REWARD';
export const USER_TRIGGER_PAYMENT = 'USER_TRIGGER_PAYMENT';
export const USER_COMPLETE_PAYMENT = 'USER_COMPLETE_PAYMENT';
export const USER_DELETE = 'USER_DELETE';

export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_ITEMS_GET = 'PROJECT_ITEMS_GET';
export const PROJECTS_ALL = 'PROJECTS_ALL';
export const PROJECT_PUT = 'PROJECT_PUT';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_ADD_USER = 'PROJECT_ADD_USER';
export const PROJECT_ACCEPT_INVITE = 'PROJECT_ACCEPT_INVITE';
export const PROJECT_UPDATE_USER_PERMISSIONS = 'PROJECT_UPDATE_USER_PERMISSIONS';
export const PROJECT_REMOVE_USER = 'PROJECT_REMOVE_USER';
export const PROJECT_SHARE_ITEMS = 'PROJECT_SHARE_ITEMS';

export const ROOM_POST = 'ROOM_POST';
export const ROOM_GET = 'ROOM_GET';
export const ROOM_PUT = 'ROOM_PUT';
export const ROOM_DELETE = 'ROOM_DELETE';

export const ITEM_POST = 'ITEM_POST';
export const ITEM_GET = 'ITEM_GET';
export const ITEM_PUT = 'ITEM_PUT';
export const ITEM_SHARE = 'ITEM_SHARE';
export const ITEM_DELETE = 'ITEM_DELETE';

export const CHECKLIST_GET = 'CHECKLIST_GET';
export const CHECKLISTS_GET = 'CHECKLISTS_GET';
export const CHECKLIST_ITEM_COMPLETE = 'CHECKLIST_ITEM_COMPLETE';
export const CHECKLIST_ITEM_INCOMPLETE = 'CHECKLIST_ITEM_INCOMPLETE';
export const CHECKLIST_ITEM_ON_WISHLIST = 'CHECKLIST_ITEM_ON_WISHLIST';
export const CHECKLIST_ITEM_NOT_ON_WISHLIST = 'CHECKLIST_ITEM_NOT_ON_WISHLIST';

export const ADMIN_CHECKLIST_POST = 'ADMIN_CHECKLIST_POST';
export const ADMIN_CHECKLIST_GET = 'ADMIN_CHECKLIST_GET';
export const ADMIN_CHECKLIST_PUT = 'ADMIN_CHECKLIST_PUT';
export const ADMIN_CHECKLIST_DELETE = 'ADMIN_CHECKLIST_DELETE';
export const ADMIN_CHECKLISTS_GET = 'ADMIN_CHECKLISTS_GET';

export const ADMIN_CHECKLIST_ITEM_POST = 'ADMIN_CHECKLIST_ITEM_POST';
export const ADMIN_CHECKLIST_ITEM_PUT = 'ADMIN_CHECKLIST_ITEM_PUT';
export const ADMIN_CHECKLIST_ITEM_DELETE = 'ADMIN_CHECKLIST_ITEM_DELETE';

export const EVENT_POST = 'EVENT_POST';
export const EVENTS_GET = 'EVENTS_GET';
export const EVENTS_GLOBAL_GET = 'EVENTS_GLOBAL_GET';
export const EVENT_DELETE = 'EVENT_DELETE';
