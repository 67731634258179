/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { fileToBase64Packer } from '../images/fileToBase64packer';

/**
 * Returns the created project
 * @param {String} token Verification token
 * @param {Object} project { name, address, picture }
 */
export async function projectCreate(token, project) {
  const body = {
    name: project.name,
    address: project.address,
    timeline: project.timeline,
    picture: await fileToBase64Packer(project.picture),
  };
  return axios.post(
    '/api/project',
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets the project corresponding to the given id
 * @param {String} token Verification token
 * @param {String} projectId Project id
 */
export async function projectGet(token, projectId) {
  return axios.get(
    `/api/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets the project items
 * @param {String} token Verification token
 * @param {String} projectId Project id
 */
export async function projectItemsGet(token, projectId) {
  return axios.get(
    `/api/project/${projectId}/items`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

export async function projectsAll(token) {
  return axios.get(
    '/api/projects',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Updates the project based on given id
 * @param {String} token Verification token
 * @param {Object} data { projectId, project }
 */
export async function projectPut(token, data) {
  const body = {
    ...data.project,
  };
  if (data.project.picture) body.picture = await fileToBase64Packer(data.project.picture);

  return axios.put(
    `/api/project/${data.projectId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the project corresponding to the given id
 * @param {String} token Verification token
 * @param {String} projectId Project Id
 */
export async function projectDelete(token, projectId) {
  return axios.delete(
    `/api/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Accepts the invite to join the project via the given token
 * @param {String} token Verification token
 * @param {String} inviteToken Invite token for project
 */
export async function projectAcceptInvite(token, inviteToken) {
  return axios.post(
    '/api/project/add_user',
    {
      add_user_to_project_token: inviteToken,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Sends request to add user to project
 * @param {String} token Verification token
 * @param {String} data {projectId, email, permissions}
 */
export async function projectAddUser(token, data) {
  return axios.post(
    `/api/project/${data.projectId}/send_add_user_to_project_email`,
    {
      user_email: data.email,
      user_type: data.permissions,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Sends request to update user permissions
 * @param {String} token Verification token
 * @param {String} data { projectId, userId, permissions }
 */
export async function projectUpdateUserPermissions(token, data) {
  return axios.put(
    `/api/project/${data.projectId}/edit_user`,
    {
      user_id: data.userId,
      user_type: data.permissions,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Sends request to remove user from project
 * @param {String} token Verification token
 * @param {String} data {projectId, userId}
 */
export async function projectRemoveUser(token, data) {
  return axios.delete(
    `/api/project/${data.projectId}/delete_user?user_id=${data.userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Shares a project's items with the given list of emails
 * @param {String} token Verification token
 * @param {String} data { projectId, emails }
 */
export async function projectShareItems(token, { projectId, emails, category }) {
  return axios.post(
    `/api/project/${projectId}/share_items`,
    {
      emails,
      category,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
