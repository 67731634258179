/* eslint-disable import/no-extraneous-dependencies */

import { Factory } from 'miragejs';
import faker from 'faker';
import { userData } from './testData';
import * as memoryQuestions from '../assets/json/memoryQuestions.json';

export default {
  project: Factory.extend({
    name(i) { return `Project ${i + 1}`; },
    owner: {
      id: userData.id,
      name: userData.name,
      email: userData.email,
    },
    admins: [
      {
        id: 2,
        name: 'John Shepard',
        email: 'johnshepard@atlantis.ps',
      },
    ],
    users: [
      {
        id: 3,
        name: 'Rodney McKay',
        email: 'rodneymckay@atlantis.ps',
      },
    ],
    address: 'A2C 4E6',
    postal_code: 'A2C 4E6',
    picture: {
      url: '/img/default-project-images/house.jpg',
    },
    // timeline: JSON.stringify({
    //   start_date: '2021-04-01T23:14:48.136Z',
    //   ...firstCategory,
    // }),
    timeline: null,
  }),
  room: Factory.extend({
    name(i) { return `Room ${i + 1}`; },
    floor: 'Room floor',
    picture: {
      url: '/img/default-room-images/room.jpg',
    },
  }),
  item: Factory.extend({
    name(i) {
      if (i === -1) return i;
      return faker.commerce.productName();
    },
    category(i) { return ['Moving', 'Sale', 'Storage', 'Junk', 'Donate', 'Memory'][i % 6]; },
    description: faker.lorem.paragraph(),
    memory_info(i) {
      if (i === -1) return i;
      const info = {};
      memoryQuestions.memoryQuestions.forEach((key) => {
        info[key] = faker.lorem.paragraph();
      });
      return info;
    },
    images(i) { return [`/img/default-item-images/${['Moving', 'Sale', 'Storage', 'Junk', 'Donate', 'Memory'][i % 6]}.jpeg`]; },
    picture: {
      url: '/img/default-item-images/Mirage.jpeg',
    },
  }),
  checklist: Factory.extend({
    name(i) {
      if (i === -1) return i;
      return faker.commerce.productName();
    },
    type(i) { return i % 2 === 0 ? 'Service' : 'Safety'; },
    picture: { url: '/img/default-item-images/Mirage.jpeg' },
  }),
  reward: Factory.extend({
    name(i) {
      if (i === -1) return i;
      return faker.commerce.productName();
    },
    description(i) {
      if (i === -1) return i;
      return faker.lorem.paragraph().substring(0, 20);
    },
    value(i) {
      return ((i % 3) + 1) * 100;
    },
  }),
  checklistItem: Factory.extend({
    name(i) {
      if (i === -1) return i;
      return faker.commerce.productName();
    },
    description(i) {
      if (i === -1) return i;
      return faker.lorem.paragraph();
    },
    url(i) {
      if (i % 2 === 0) return null;
      return 'https://www.amazon.ca';
    },
    completed: false,
    on_wishlist: false,
  }),
};
