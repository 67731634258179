import {
  PROJECT_GET,
  ROOM_POST, ROOM_GET, ROOM_PUT, ROOM_DELETE,
} from '@/store/actions';
import { ROOM_SET } from '@/store/mutations';
import {
  USER_TOKEN,
} from '@/store/getters';
import {
  updateRoomFields, getRoomFields,
} from '@/store/mappers';
import {
  roomCreate, roomGet, roomPut, roomDelete,
} from '@/utils/api/room';

const actions = {
  async [ROOM_POST](context, room) {
    const token = context.getters[USER_TOKEN];
    return roomCreate(token, room);
  },
  async [ROOM_GET](context, ids) {
    const token = context.getters[USER_TOKEN];
    const retrievedRoom = roomGet(token, ids)
      .then((room) => context.commit(ROOM_SET, room));
    context.dispatch(PROJECT_GET, ids.projectId)
      .catch((error) => { throw error; });
    return retrievedRoom;
  },
  async [ROOM_PUT](context, room) {
    const token = context.getters[USER_TOKEN];
    return roomPut(token, room);
  },
  async [ROOM_DELETE](context, roomId) {
    const token = context.getters[USER_TOKEN];
    return roomDelete(token, roomId);
  },
};
const mutations = {
  [ROOM_SET]: (state, room) => {
    state.room = room;
  },
  updateRoomFields,
};
const getters = {
  getRoomFields,
};
const state = () => ({
  room: {},
  rooms: {},
});

export default {
  state,
  mutations,
  actions,
  getters,
};
