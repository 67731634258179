/* eslint-disable import/prefer-default-export */
export const USER_NAME = 'USER_NAME';
export const USER_IMAGE = 'USER_IMAGE';
export const USER_DETAILS = 'USER_DETAILS';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_IS_ADMIN = 'USER_IS_ADMIN';
export const USER_INVITED_EMAILS = 'USER_INVITED_EMAILS';
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS';
export const USER_PLAN = 'USER_PLAN';
export const USER_POINTS = 'USER_POINTS';
export const USER_WALKTHROUGH_STATE = 'USER_WALKTHROUGH_STATE';

export const PROJECT_INVITE_TOKEN = 'ADD_USER_TO_PROJECT_TOKEN';
export const PROJECT_PERMISSIONS = 'PROJECT_PERMISSIONS';
export const PROJECT_PERMISSIONS_CURRENT = 'PROJECT_PERMISSIONS_CURRENT';
