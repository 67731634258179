import {
  Model, hasMany, belongsTo,
} from 'miragejs';

export default {
  user: Model.extend({}),

  reward: Model.extend({}),

  project: Model.extend({
    rooms: hasMany(),
    items: hasMany(),
  }),
  room: Model.extend({
    project: belongsTo(),
    items: hasMany(),
  }),
  item: Model.extend({
    room: belongsTo(),
    project: belongsTo(),
  }),

  checklist: Model.extend({
    checklist_items: hasMany(),
  }),

  checklistItem: Model.extend({
    checklist: belongsTo(),
  }),

  event: Model.extend(),
};
