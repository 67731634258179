/* eslint-disable import/prefer-default-export */
export const USER_STORE_DETAILS = 'USER_STORE_DETAILS';
export const USER_WALKTHROUGH_COMMIT = 'USER_WALKTHROUGH_COMMIT';
export const USER_REMOVE_DETAILS = 'USER_REMOVE_DETAILS';
export const UPDATE_USER_PLAN = 'UPDATE_USER_PLAN';
export const USER_STORE_TOKEN = 'USER_STORE_TOKEN';
export const USER_REMOVE_TOKEN = 'USER_REMOVE_TOKEN';
export const USER_UPDATE_POINTS = 'USER_UPDATE_POINTS';

export const PROJECT_ACCEPT_INVITE_TOKEN_SETTER = 'PROJECT_ACCEPT_INVITE_TOKEN_SETTER';
export const PROJECT_SET = 'PROJECT_SET';
export const PROJECTS_SET = 'PROJECTS_SET';
export const PROJECT_ITEMS_SET = 'PROJECT_ITEMS_SET';

export const ROOM_SET = 'ROOM_SET';

export const ITEM_SET = 'ITEM_SET';
