import {
  USER_CREATE, USER_SIGN_IN, USER_GET, USER_EMAIL_AUTHENTICATE,
  USER_RESET_PASSWORD, USER_FORGOT_PASSWORD, USER_INVITE, USER_NOTIFICATION_READ,
  USER_TRIGGER_PAYMENT, USER_COMPLETE_PAYMENT, USER_DELETE, USER_EDIT, USER_ADD_POINTS,
  USER_GET_AVAILABLE_REWARDS, USER_REDEEM_REWARD, USER_WALKTHROUGH_DISPATCH,
  // PROJECTS_ALL,
} from '@/store/actions';
import {
  USER_STORE_DETAILS, USER_REMOVE_DETAILS, USER_REMOVE_TOKEN, USER_STORE_TOKEN,
  UPDATE_USER_PLAN, USER_UPDATE_POINTS, USER_WALKTHROUGH_COMMIT,
} from '@/store/mutations';
import {
  USER_NAME, USER_DETAILS, USER_TOKEN,
  USER_IS_ADMIN, USER_INVITED_EMAILS, USER_IMAGE,
  USER_NOTIFICATIONS, USER_PLAN, USER_POINTS,
  USER_WALKTHROUGH_STATE,
} from '@/store/getters';
import {
  userCreate, userSignIn, userData, userValidateEmail, userResetPassword,
  userForgotPassword, userInvite, userNotificationRead,
  userTriggerPayment, userDeleteAccount, userEdit, userCompletePayment,
  userPointsAdd, userAvailableRewards, userRedeemReward, userWalkthroughUpdate,
} from '@/utils/api/user';
import { setDarkMode, setSavedDarkMode } from '../../utils/darkMode';

const actions = {
  async [USER_CREATE](context, user) {
    return userCreate(user);
  },
  async [USER_EDIT](context, user) {
    const token = context.getters[USER_TOKEN];
    return userEdit(token, user);
  },
  async [USER_SIGN_IN](context, userCredentials) {
    return userSignIn(userCredentials)
      .then((user) => {
        context.commit(USER_STORE_DETAILS, {
          ...user,
        });
        context.commit(USER_STORE_TOKEN, user.token);
      });
  },
  async [USER_EMAIL_AUTHENTICATE](context, emailToken) {
    return userValidateEmail(emailToken)
      .then((data) => {
        context.commit(USER_STORE_TOKEN, data.token);
        context.dispatch(USER_GET, data.token)
          .then((user) => {
            context.commit(USER_STORE_DETAILS, {
              ...user,
            });
            context.commit(USER_STORE_TOKEN, user.token);
          })
          .catch(() => {
            console.error('Error has occured');
          });
      })
      .catch(() => {
        context.commit(USER_REMOVE_DETAILS);
        context.commit(USER_REMOVE_TOKEN);
      });
  },
  async [USER_GET](context, token) {
    return userData(token || context.getters[USER_TOKEN])
      .then(async (user) => {
        context.commit(USER_STORE_DETAILS, user);
        return user;
      });
  },
  async [USER_RESET_PASSWORD](context, resetPasswordAndToken) {
    return userResetPassword(resetPasswordAndToken);
  },
  async [USER_FORGOT_PASSWORD](context, email) {
    return userForgotPassword(email);
  },
  async [USER_WALKTHROUGH_DISPATCH]({ commit, state, getters }, newStage) {
    const token = getters[USER_TOKEN];
    let updatedStage = state.activeWalkthroughStage + 1;
    if (newStage !== undefined) updatedStage = newStage;
    if (state.user.walkthrough_stage !== 100) {
      const newUserData = await userWalkthroughUpdate(token, 100);
      commit(USER_STORE_DETAILS, newUserData);
    }
    commit(USER_WALKTHROUGH_COMMIT, updatedStage);
  },
  async [USER_INVITE](context, email) {
    const token = context.getters[USER_TOKEN];
    return userInvite(token, email)
      .then((data) => {
        context.state.user.invited_emails.push(email);
        context.dispatch(USER_ADD_POINTS, 'Invite a Friend');
        return data;
      });
  },
  async [USER_NOTIFICATION_READ](context, notificationId) {
    const token = context.getters[USER_TOKEN];
    return userNotificationRead(token, notificationId);
  },
  async [USER_TRIGGER_PAYMENT](context, data) {
    const token = context.getters[USER_TOKEN];
    return userTriggerPayment(token, data);
  },
  async [USER_COMPLETE_PAYMENT](context, code) {
    const token = context.getters[USER_TOKEN];
    userCompletePayment(token, code)
      .then(({ plan }) => context.commit(UPDATE_USER_PLAN, plan));
  },
  async [USER_ADD_POINTS](context, action) {
    const token = context.getters[USER_TOKEN];
    return userPointsAdd(token, action)
      .then(({ points }) => context.commit(USER_UPDATE_POINTS, points));
  },
  async [USER_REDEEM_REWARD](context, data) {
    const token = context.getters[USER_TOKEN];
    return userRedeemReward(token, data)
      .then(async ({ coupon_code: couponCode }) => {
        await context.dispatch(USER_GET);
        return couponCode;
      });
  },
  async [USER_GET_AVAILABLE_REWARDS](context, user) {
    const token = context.getters[USER_TOKEN];
    return userAvailableRewards(token, user);
  },
  async [USER_DELETE](context) {
    const token = context.getters[USER_TOKEN];
    userDeleteAccount(token)
      .then(() => {
        context.commit(USER_REMOVE_DETAILS);
        context.commit(USER_REMOVE_TOKEN);
        window.location = '/sign-in';
      });
  },
};
const mutations = {
  [USER_UPDATE_POINTS](state, points) { state.user.points = points; },
  [USER_WALKTHROUGH_COMMIT](state, newStage) { state.activeWalkthroughStage = newStage; },
  [USER_STORE_DETAILS](state, user) {
    state.user = {
      ...state.user,
      ...user,
    };
    if (!state.user.invited_emails) state.user.invited_emails = [];
    if (!state.user.picture) {
      state.user.picture = { url: '/img/general-defaults/user.jpeg' };
    }
    if (!state.user.walkthrough_stage || state.user.walkthrough_stage === 0) {
      state.activeWalkthroughStage = 0;
    }
  },
  [USER_REMOVE_DETAILS](state) {
    localStorage.removeItem(`${process.env.NODE_ENV}-token`);
    state.user = {
      name: '',
      email: '',
      country: '',
      postal_code: '',
      invited_emails: [],
      admin: false,
      id: null,
      points: 0,
      plan: null,
    };
    state.token = undefined;
  },
  [UPDATE_USER_PLAN](state, plan) { state.user.plan = plan; },
  [USER_STORE_TOKEN](state, token) {
    localStorage.setItem(`${process.env.NODE_ENV}-token`, token);
    state.token = token;
  },
  [USER_REMOVE_TOKEN](state) {
    localStorage.removeItem(`${process.env.NODE_ENV}-token`);
    state.token = undefined;
  },
  SET_DARK_MODE(state, newVal) {
    state.darkModeEnabled = newVal;
    setDarkMode(newVal);
  },
};
const getters = {
  [USER_NAME]: (state) => state.user.name,
  [USER_PLAN]: (state) => state.user.plan,
  [USER_POINTS]: (state) => state.user.points || 0,
  [USER_IMAGE]: (state) => (
    state.user.picture
      ? state.user.picture.url
      : '/img/general-defaults/user.jpeg'
  ),
  [USER_DETAILS]: (state) => state.user,
  [USER_TOKEN]: (state) => state.token,
  [USER_IS_ADMIN]: (state) => !!state.user.admin,
  [USER_INVITED_EMAILS]: (state) => state.user.invited_emails || [],
  [USER_NOTIFICATIONS]: (state) => (state.user.notifications || []).reverse(),
  [USER_WALKTHROUGH_STATE]: (state) => state.activeWalkthroughStage,
  GET_DARK_MODE: (state) => state.darkModeEnabled,
};
const state = () => ({
  darkModeEnabled: setSavedDarkMode(),
  user: {
    name: '',
    email: '',
    country: '',
    postal_code: '',
    id: null,
    admin: false,
    points: 0,
    plan: null,
    invited_emails: [],
    walkthrough_stage: -1,
  },
  activeWalkthroughStage: 100,
  token: null,
});

export default {
  state,
  mutations,
  actions,
  getters,
};
