/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { fileToBase64Packer } from '../images/fileToBase64packer';

/**
 * Creates an item corresponding to the given projectId and roomId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, item }
 */
export async function itemCreate(token, data) {
  const body = {
    name: data.item.name,
    category: data.item.category,
    description: data.item.description,
    memory_info: data.item.memory_info,
    picture: await fileToBase64Packer(data.item.picture),
  };
  if (data.item.picture_2) body.picture_2 = await fileToBase64Packer(data.item.picture_2);
  if (data.item.picture_3) body.picture_3 = await fileToBase64Packer(data.item.picture_3);

  return axios.post(
    `/api/project/${data.projectId}/room/${data.roomId}/item`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Gets the item corresponding to the given projectId, roomId, and itemId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, itemId }
 */
export async function itemGet(token, ids) {
  return axios.get(
    `/api/project/${ids.projectId}/room/${ids.roomId}/item/${ids.itemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Updates the item corresponding to the given projectId, roomId, and itemId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, itemId, room }
 */
export async function itemPut(token, data) {
  const body = {
    name: data.item.name,
    category: data.item.category,
    description: data.item.description,
    memory_info: data.item.memory_info,
  };
  if (data.item.picture) body.picture = await fileToBase64Packer(data.item.picture);
  if (data.item.picture_2) body.picture_2 = await fileToBase64Packer(data.item.picture_2);
  if (data.item.picture_3) body.picture_3 = await fileToBase64Packer(data.item.picture_3);
  return axios.put(
    `/api/project/${data.projectId}/room/${data.roomId}/item/${data.itemId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Share the item
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, itemId, emails }
 */
export async function itemShare(token, {
  projectId, roomId, itemId, emails,
}) {
  return axios.post(
    `/api/project/${projectId}/room/${roomId}/item/${itemId}/share`,
    { emails },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Deletes the item corresponding to the given projectId, roomId, itemId
 * @param {String} token Validation token
 * @param {Object} data { projectId, roomId, itemId }
 */
export async function itemDelete(token, ids) {
  return axios.delete(
    `/api/project/${ids.projectId}/room/${ids.roomId}/item/${ids.itemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
