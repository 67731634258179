// eslint-disable-next-line import/prefer-default-export
export const userData = {
  name: 'James Appleseed',
  email: 'jamesdouitsis@gmail.com',
  id: 1,
  admin: true,
  password: 'password',
  invited_emails: [],
  country: 'Canada',
  postal_code: 'M1H 2G6',
  points: 20000,
  plan: null,
  walkthrough_stage: 10,
  timeline: null,
};
