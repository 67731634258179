/* eslint-disable import/prefer-default-export */
import axios from 'axios';

/**
 * Returns all of the checklists
 * @param {String} token Verification token
 * @param {Object} projectId Id for the project owning all the checklists
 */
export async function checklistsGet(token, projectId) {
  return axios.get(
    `/api/project/${projectId}/lists`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => ([
    ...response.data.map((checklist) => ({
      name: checklist.name,
      type: checklist.type,
      picture: checklist.picture,
      items: checklist.items.map((item) => ({
        name: item.name,
        url: item.url,
        description: item.description,
        completed: !!item.completed,
        onWishlist: !!item.on_wishlist,
        id: item.id,
      })),
      id: checklist.id,
    })),
  ]));
}

/**
 * Returns the specified checklist
 * @param {String} token Verification token
 * @param {Object} ids { projectId, checklistId }
 */
export async function checklistGet(token, ids) {
  return axios.get(
    `/api/project/${ids.projectId}/list/${ids.checklistId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => ({
    name: response.data.name,
    picture: response.data.picture,
    type: response.data.type,
    id: response.data.id,
    items: response.data.items.map((item) => ({
      name: item.name,
      url: item.url,
      description: item.description,
      completed: !!item.completed,
      onWishlist: !!item.on_wishlist,
      id: item.id,
    })),
  }));
}

/**
 * Sends request to update user permissions
 * @param {String} token Verification token
 * @param {String} data { projectId, checklistId, checklistItemId, checklistData }
 */
export async function checklistItemPut(token, data) {
  return axios.put(
    `/api/project/${data.projectId}/list/${data.checklistId}/list_item/${data.checklistItemId}`,
    {
      completed: data.checklistData.isComplete,
      on_wishlist: data.checklistData.onWishlist,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Marks the given checklist item complete
 * @param {String} token Verification token
 * @param {String} data { projectId, checklistId, checklistItemId }
 */
export async function checklistItemComplete(token, data) {
  return axios.post(
    `/api/project/${data.projectId}/list/${data.checklistId}/list_item/${data.checklistItemId}/complete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Marks the given checklist item incomplete
 * @param {String} token Verification token
 * @param {String} data { projectId, checklistId, checklistItemId }
 */
export async function checklistItemIncomplete(token, data) {
  return axios.delete(
    `/api/project/${data.projectId}/list/${data.checklistId}/list_item/${data.checklistItemId}/complete`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Marks the given item as on the wishlist
 * @param {String} token Verification token
 * @param {String} data { projectId, checklistId, checklistItemId }
 */
export async function checklistItemOnWishlist(token, data) {
  return axios.post(
    `/api/project/${data.projectId}/list/${data.checklistId}/list_item/${data.checklistItemId}/wishlist`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}

/**
 * Marks the given item as not on the wishlist
 * @param {String} token Verification token
 * @param {String} data { projectId, checklistId, checklistItemId }
 */
export async function checklistItemNotOnWishlist(token, data) {
  return axios.delete(
    `/api/project/${data.projectId}/list/${data.checklistId}/list_item/${data.checklistItemId}/wishlist`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((response) => response.data);
}
